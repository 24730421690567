<template>
    <el-main>
        <el-form :model="form" status-icon :rules="rules" ref="form" label-width="130px">
            <el-form-item label="banner名称：" prop="banner_name">
                <el-input v-model="form.banner_name"></el-input>
            </el-form-item>
            <el-form-item label="banner图片：" prop="banner_picture">
                <ReadyUploadSource
                :path="form.banner_picture"
                @getSource="val => (form.banner_picture = val.path)"
                @removeThis="form.banner_picture = ''"
                :showStyle="{
                    width: '320px',
                    height: '100px',
                }"
                :maxSize="1024 * 1024 * 20"
                ></ReadyUploadSource>
                <div class="tips">建议视频尺寸1920*600px，大小不超过20M</div>
            </el-form-item>
            <el-form-item label="banner位置：" prop="banner_location">
                <el-select v-model="form.banner_location">
                    <el-option v-for="(item, index) in position" :key="index" :label="item.name" :value="item.val"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="跳转链接：" prop="jump_url">
                <el-input v-model="form.jump_url"></el-input>
            </el-form-item>
            <el-form-item label="是否显示：" prop="is_show">
                <el-switch v-model="form.is_show" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
        </el-form>
        <Preservation @preservation="Preservation"></Preservation>
    </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
    components: {
        Preservation,
        ReadyUploadSource,
    },
    data() {
        return {
            form:{
                banner_name:'',
                banner_picture:'',
                banner_location:'',
                jump_url:'',
                is_show:1
            },
            rules:{
                banner_name: [{ required: true, message: '请填写banner名称', trigger: 'blur' }],
                banner_picture: [{ required: true, message: '请上传banner图片', trigger: 'change' }],
                banner_location: [{ required: true, message: '请选择banner位置', trigger: 'change' }],
            },
            position:[{
                name:'首页',
                val:'index'
            },{
                name:'关于我们',
                val:'about_us'
            },{
                name:'项目介绍',
                val:'project_introduction'
            },{
                name:'愿景使命',
                val:'vision_mission'
            }],
            is_add:true
        };
    },
    created() {
        if (this.$route.query.id) {
            this.form.id = this.$route.query.id
            this.is_add = false
            this.getInfo();
        }
    },
    methods: {
        Preservation() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    let url = this.is_add?this.$api.PCOfficialWebsite.addBanner:this.$api.PCOfficialWebsite.editBanner
                    this.$axios.post(url,this.form).then(res => {
                        if (res.code == 0) {
                            let msg = this.is_add?'添加成功':'编辑成功'
                            this.$message.success(msg);
                            this.$router.go(-1)
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            });
        },
        getInfo(){
            this.$axios.post(this.$api.PCOfficialWebsite.getBannerInfo,{id:this.form.id}).then(res => {
                if (res.code == 0) {
                    let info = res.result
                    this.form.banner_name = info.banner_name
                    this.form.banner_picture = info.banner_picture
                    this.form.banner_location = info.banner_location
                    this.form.jump_url = info.jump_url
                    this.form.is_show = info.is_show
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
  min-height: 100%;
  background-color: #fff;
  padding-bottom: 80px;
  .el-form {
    .el-input,
    .el-select {
      width: 400px;
    }
    .tips {
      color: #a1a1a1;
      font-size: 12px;
    }
  }
}
</style>
